import React, { useState, useEffect, Dispatch, SetStateAction, useCallback } from "react";
import { makeStyles, TableBody } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import StyledTableRow from './StyledTableRow';
import { ActionButtonsContainer } from ".";
import { Package, User, UserPackage } from "../../models";
import { getPackageList } from "../../services/userService";
import { RootState } from "../../store/RootReducer";
import SelectedPackageItem from './SelectedPackageItem';
import { GenericLoader } from '../../components/GenericLoader';
interface Props {
  inError: boolean;
  isUserEdit: boolean;
  editingUser: User | null;
  saving: boolean;
  hasChangedForm: boolean;
  setPackagesToDelete: Dispatch<SetStateAction<Package[]>>;
  setPackagesToAdd: Dispatch<SetStateAction<Package[]>>;
}

const useStyles = makeStyles(() => ({
  package__lineOne: {
    fontWeight: "bold",
  },
  package__lineTwo: {
    paddingLeft: 20,
    fontSize: "0.8rem",
  },
  package__container: {
    maxWidth: "calc(100% - 48px)",
  },
  package__selector__container: {
    minHeight: 48,
  },
  package__selector: {
    width: "calc(100% - 12px)",
  },
  no__border: {
    border: "none",
    "&:focus": {
      backgroundColor: "rgba(255, 255, 255, 0)",
    },
  },
  package__scroll: {
    width: "100%",
    height: 400,
    overflowY: "scroll",
  },
  package__loader: {
    paddingTop: '16px',
  },
}));

// eslint-disable-next-line sonarjs/cognitive-complexity
export const PackageSelectorContainer = (props: Props): React.ReactElement => {
  const {
    inError,
    isUserEdit,
    editingUser,
    saving,
    hasChangedForm,
    setPackagesToDelete,
    setPackagesToAdd,
  } = props;
  const { t } = useTranslation("user");
  const classes = useStyles();
  const userState = useSelector((state: RootState) => state.userState);
  const [packages, setPackages] = useState<Package[]>();
  const [newlySelectedPackage, setNewlySelectedPackage] = useState<string>("");
  const [loadingPackageList, setLoadingPackageList] = useState(true);
  const [localUserPackages, setLocalUserPackages] = useState<UserPackage[]>([]);

  const addNewPackageForUser = useCallback((packageId: string) => {
    setNewlySelectedPackage("");
    const item = packages?.find((x) => x.id === (packageId));
    const mapped: UserPackage = {
      accessPackageId: item?.id ?? "",
      accessPackagePolicyId: item?.assignmentPolicyId ?? ""
    }
    item && setLocalUserPackages((prev) => [mapped, ...prev]);
    item && setPackagesToDelete((prev) => prev.filter((x) => x.id !== item.id));

    if (!editingUser?.accessPackages.map(x => x.accessPackageId).includes(packageId)) {
      item && setPackagesToAdd(prev => [item, ...prev]);
    }
  }, [editingUser?.accessPackages, packages, setPackagesToAdd, setPackagesToDelete])

  useEffect(() => {
    setLocalUserPackages(editingUser?.accessPackages ?? []);
  }, [editingUser?.accessPackages]);

  useEffect(() => {
    if (userState.loggedInUser.administrativeUnits[0]) {
      getPackageList(userState.loggedInUser.administrativeUnits[0])
        .then(
          (data) => {
            setPackages(data);
            setLoadingPackageList(false);
          }
        );
    }
  }, [userState.loggedInUser.administrativeUnits]);

  useEffect(() => {
    // There are packages that need to be selected by default
    packages?.filter(x => x.starterPackage).forEach(x => addNewPackageForUser(x.id));
  }, [packages, addNewPackageForUser]);


  const handlePackageSelected = (event: React.ChangeEvent<{ value: unknown }>) => {
    const id = event.target.value as string;
    addNewPackageForUser(id);
  };

  const deleteAccessPackage = (id: string) => {
    const item = packages?.find((x) => x.id === id);
    setLocalUserPackages((prev) => prev.filter((x) => x.accessPackageId !== id));
    item && setPackagesToAdd((prev) => prev.filter((x) => x.id !== item.id));

    if (editingUser?.accessPackages.map(x => x.accessPackageId).includes(id)) {
      item && setPackagesToDelete(prev => [item, ...prev]);
    }
  };

  return (
    <Grid
      item
      container
      direction="column"
      justify="space-between"
      xs={12}
      md={6}
      xl={8}
      className="p-8"
      spacing={2}
    >
      <Grid item container direction="column" spacing={2}>
        <Grid item>
          <h3>{t("sub-title.packages")}</h3>
        </Grid>
        <Grid item className={classes.package__scroll}>
          <Table size="small">
            <TableBody>
              <StyledTableRow>
                <TableCell colSpan={2}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    className={classes.package__selector__container}
                  >
                    <FormControl className={classes.package__selector}>
                      <Select
                        MenuProps={{
                          disableScrollLock: true
                        }}
                        displayEmpty
                        itemType="string"
                        renderValue={(id: unknown) =>
                          id !== "" ? (
                            <div>
                              {
                                packages?.find((x) => x.id === (id as string))
                                  ?.displayName
                              }
                            </div>
                          ) : (
                            t("new-package")
                          )
                        }
                        classes={{
                          root: classes.no__border,
                        }}
                        disabled={saving || loadingPackageList}
                        disableUnderline
                        value={newlySelectedPackage}
                        onChange={handlePackageSelected}
                      >
                        {packages?.map((item: Package) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            disabled={localUserPackages
                              .map((x) => x.accessPackageId)
                              .includes(item.id)}
                          >
                            {item.displayName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </TableCell>
              </StyledTableRow>
              <GenericLoader className={classes.package__loader} loading={loadingPackageList}>
                {
                  packages?.map((row: Package) => (
                    <React.Fragment key={row.id}>
                      {
                        localUserPackages.map((x) => x.accessPackageId).includes(row.id)
                          ? <SelectedPackageItem
                            item={row}
                            saving={saving}
                            onDeleteClick={deleteAccessPackage}
                          />
                          : null
                      }
                    </React.Fragment>
                  ))
                }

              </GenericLoader>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <ActionButtonsContainer
        inError={inError}
        isUserEdit={isUserEdit}
        saving={saving}
        editingUser={editingUser}
        hasChangedForm={hasChangedForm}
      />
    </Grid>
  );
};
