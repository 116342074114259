import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';

import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  countText: {
    fontSize: "0.9rem"
  },
  icon__padding: {
    paddingTop: 9,
    marginRight: -8
  }
}));

interface Props {
  count: number;
}

export const CountLoader: React.FC<Props> = (props: Props) => {
  const { count } = props;
  const classes = useStyles();

  return (
    <Typography
      color="primary"
      align="right"
      className={classes.countText}
    >
      {count ?? <ClearRoundedIcon className={classes.icon__padding} />}
    </Typography>
  )
}
