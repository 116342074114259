import { ReactElement, useState, useEffect } from "react";
import { Package, PackageAssignedUser, QueryParameters, RowUser } from "../../models";
import { useTranslation } from "react-i18next";
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, makeStyles } from "@material-ui/core";
import TablePagination from '@material-ui/core/TablePagination';
import React from "react";
import { addPackages } from "../../services/userService";

const ADD_PACKAGE_LABEL = 'Add Package';

const useStyles = makeStyles(() => ({
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    table__container: {
        paddingTop: 32,
    }
}));

interface SuggestedUsersProps {
    suggestions: RowUser[]
    setUsersSuggestions: React.Dispatch<React.SetStateAction<RowUser[]>>
    packageInfo: Package
    usersWithPackage: PackageAssignedUser[]
    setUsersWithPackage: React.Dispatch<React.SetStateAction<PackageAssignedUser[] | null>>
    setUserNumber: React.Dispatch<React.SetStateAction<number>>
    setSuggestionCount: React.Dispatch<React.SetStateAction<number>>
}


export const SuggestedUsers = (props: SuggestedUsersProps): ReactElement => {
    const classes = useStyles();
    const { suggestions, setUsersSuggestions, packageInfo, usersWithPackage, setUsersWithPackage, setUserNumber, setSuggestionCount } = props
    const { t } = useTranslation('home');
    const [totalUsers, setTotalUsers] = useState(0);
    const [orderByParam, setOrderByParam] = useState("displayName")
    const [allChecked, setAllChecked] = useState<boolean>(false)
    const [searchQuery, setSearchQuery] = useState<QueryParameters>({
        order: "asc",
        orderBy: orderByParam,
        rowsPerPage: 25,
        page: 0,
    });
    const [buttonStates, setButtonStates] = useState<{ [key: string]: string }>(
        suggestions.reduce((acc, row) => ({ ...acc, [row.id]: ADD_PACKAGE_LABEL }), {})
    );

    const [disabledRows, setDisabledRows] = useState<{ [key: string]: boolean }>({});


    useEffect(() => {
        setTotalUsers(suggestions.length)
    }, [allChecked, suggestions])

    const compareName = (): void => {
        const copyArray = suggestions.concat()
        if (searchQuery.order === 'asc') {
            copyArray.sort((a, b) => a.displayName.localeCompare(b.displayName))
        } else {
            copyArray.sort((a, b) => b.displayName.localeCompare(a.displayName))
        }
        setUsersSuggestions(copyArray)
    }

    const compareEmail = (): void => {
        const copyArray = suggestions.concat()
        if (searchQuery.order === 'asc') {
            copyArray.sort((a, b) => {
                const emailA = a.email || '\uffff'; // Replace null with a character that comes later in Unicode
                const emailB = b.email || '\uffff'; // Replace null with a character that comes later in Unicode

                return emailA.localeCompare(emailB);
            });
        } else {
            copyArray.sort((a, b) => {
                const emailA = a.email || '\uffff'; // Replace null with a character that comes later in Unicode
                const emailB = b.email || '\uffff'; // Replace null with a character that comes later in Unicode

                return emailB.localeCompare(emailA);
            });
        }
        setUsersSuggestions(copyArray)
    }

    const sortHandler = (id: string): void => {
        setSearchQuery({ order: searchQuery.order === 'asc' ? 'desc' : 'asc', orderBy: id, page: searchQuery.page, rowsPerPage: searchQuery.rowsPerPage })
        if (id === "displayName") {
            compareName()
        } else if (id === "mail") {
            compareEmail()
        }
    }

    const fetchRows = (
        order: "asc" | "desc",
        orderBy: string,
        rowsPerPage: number,
        page: number
    ): void => {
        setSearchQuery({
            order: order,
            orderBy: orderBy,
            page: page,
            rowsPerPage: rowsPerPage,
        });
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        fetchRows(searchQuery.order, searchQuery.orderBy, searchQuery.rowsPerPage, newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value, 10);
        fetchRows(searchQuery.order, searchQuery.orderBy, newRowsPerPage, 0);
    };

    const onAddUser = async (user: RowUser) => {
        try {
            // Set loading state while waiting for the response
            setButtonStates((prevButtonStates) => ({
                ...prevButtonStates,
                [user.id]: 'Access request being processed',
            }));
            setDisabledRows((prevDisabledRows) => ({
                ...prevDisabledRows,
                [user.id]: true,
            }));

            const response = await addPackages([packageInfo], user.id);

            if (response?.status === 200) {
                setUsersWithPackage(usersWithPackage.concat({ id: user.id, email: user.email, displayName: user.displayName, userPrincipalName: '' })) // adding user to users that have this access package right
                setUserNumber(number => number + 1)
                setButtonStates((prevButtonStates) => ({
                    ...prevButtonStates,
                    [user.id]: 'Access right granted',
                }));
                setDisabledRows((prevDisabledRows) => ({
                    ...prevDisabledRows,
                    [user.id]: true,
                }));
                setSuggestionCount(previousNumber => previousNumber - 1)
            } else {
                setButtonStates((prevButtonStates) => ({
                    ...prevButtonStates,
                    [user.id]: "Error, try again",
                }));
                setDisabledRows((prevDisabledRows) => ({
                    ...prevDisabledRows,
                    [user.id]: false,
                }));
            }
        } catch (error) {
            setButtonStates((prevButtonStates) => ({
                ...prevButtonStates,
                [user.id]: ADD_PACKAGE_LABEL,
            }));
            setDisabledRows((prevDisabledRows) => ({
                ...prevDisabledRows,
                [user.id]: false,
            }));
        }
    }

    return (
        <Grid
            container
            direction="column"
            className={classes.table__container}
        >
            <TableContainer component={Paper} elevation={0}>
                <Table size="small">
                    <TableHeaderContainer
                        sortHandler={sortHandler}
                        order={searchQuery.order}
                        orderBy={searchQuery.orderBy}
                    />
                    <TableBodyContainer suggestions={suggestions} addUser={onAddUser} allChecked={allChecked} setButtonStates={setButtonStates} buttonStates={buttonStates} setDisabledRows={setDisabledRows} disabledRows={disabledRows}
                        searchQueryRowsPerPage={searchQuery.rowsPerPage} searhQueryPage={searchQuery.page}
                    />
                </Table>
            </TableContainer>
            {
                suggestions.length !== 0
                    ? (
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50, 75, 100]}
                            count={totalUsers}
                            rowsPerPage={searchQuery.rowsPerPage}
                            page={searchQuery.page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            labelRowsPerPage={t('rows-per-page')}
                            component="div"
                        />
                    )
                    : null
            }
            {/* <DeletePopup
                open={openDelete}
                onClose={onCloseDeletePopup}
                email={userToDelete.email}
                userId={userToDelete.id}
            /> */}
        </Grid>
    )
}

interface TableHeaderContainerProps {
    sortHandler: (id: string) => void;
    order: 'asc' | 'desc';
    orderBy: string;
}

const TableHeaderContainer = (props: TableHeaderContainerProps) => {
    const { sortHandler, order, orderBy } = props;
    const { t } = useTranslation('home');
    const classes = useStyles();

    type header = {
        id: string;
        label: string;
        align: 'left' | 'right';
        sortable: boolean,
    };

    const headCells: header[] = [
        { id: 'displayName', label: 'displayName', align: 'left', sortable: true },
        { id: 'mail', label: 'email', align: 'left', sortable: true },
        { id: 'actions', label: '', align: 'right', sortable: false }
    ];

    return (
        <TableHead>
            <TableRow>
                {
                    headCells.map((cell) => (
                        <TableCell
                            key={cell.id}
                            align={cell.align}
                            sortDirection={orderBy === cell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === cell.id}
                                direction={orderBy === cell.id ? order : 'asc'}
                                onClick={() => { sortHandler(cell.id) }}
                            >
                                {t(cell.label)}

                                {orderBy === cell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))
                }
            </TableRow>
        </TableHead>
    );
}

interface TableBodyContainerProps {
    suggestions: RowUser[];
    addUser: (row: RowUser) => void;
    allChecked: boolean;
    setButtonStates: React.Dispatch<React.SetStateAction<{ [key: string]: string; }>>
    buttonStates: { [key: string]: string; }
    setDisabledRows: React.Dispatch<React.SetStateAction<{ [key: string]: boolean; }>>
    disabledRows: { [key: string]: boolean; }
    searchQueryRowsPerPage: number
    searhQueryPage: number
}

const TableBodyContainer: React.FC<TableBodyContainerProps> = (props) => {
    const { suggestions, addUser, allChecked, buttonStates, setButtonStates, disabledRows, setDisabledRows, searchQueryRowsPerPage, searhQueryPage } = props;
    const [selectedRows, setSelectedRows] = useState<RowUser[]>([])

    const handleAdd = (row: RowUser) => {
        addUser(row);
    };

    useEffect(() => {
        const startIndex = searhQueryPage * 10
        const endIndex = Math.min(startIndex + searchQueryRowsPerPage, suggestions.length)
        const selection = suggestions.slice(startIndex, endIndex)
        setSelectedRows(selection)

        setButtonStates((prevButtonStates) => {
            const updatedButtonStates: { [key: string]: string } = {};
            suggestions.forEach((row) => {
                updatedButtonStates[row.id] = allChecked ? 'Package requested' : ADD_PACKAGE_LABEL;
            });
            return updatedButtonStates;
        });

        setDisabledRows((prevDisabledRows) => {
            const updatedDisabledRows: { [key: string]: boolean } = {};
            suggestions.forEach((row) => {
                updatedDisabledRows[row.id] = allChecked;
            });
            return updatedDisabledRows;
        });

    }, [allChecked, suggestions, setButtonStates, setDisabledRows, searchQueryRowsPerPage, searhQueryPage]);

    const getButtonColor = (rowId: string): 'primary' | 'error' | 'success' => {
        if (buttonStates[rowId] === 'Access right granted') {
            return 'success';
        } else if (buttonStates[rowId] === 'Error, try again') {
            return 'error';
        } else {
            return 'primary';
        }
    };

    return (
        <TableBody>
            {selectedRows.map((row) => (
                <TableRow key={row.id} style={{ backgroundColor: disabledRows[row.id] ? '#d3d3d3' : 'white' }}>
                    <TableCell align="left">{row.displayName}</TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="right" width={90}>
                        <Button
                            onClick={() => handleAdd(row)}
                            variant="contained"
                            color={getButtonColor(row.id) as "inherit" | "default" | "primary" | "secondary" | undefined}
                            className={`m-r-16 ${disabledRows[row.id] ? 'disabled-button' : ''}`}
                            disabled={disabledRows[row.id]}
                            size="small"
                        >
                            {buttonStates[row.id]}
                        </Button>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>
    );
};

