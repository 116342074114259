export const msalConfig = {
  auth: {
    clientId: window.runConfig.azureAdAppId,
    authority: window.runConfig.azureAdAuthority,
    redirectUri: window.runConfig.redirectUri,
    navigateToLoginRequestUrl: false,
    postLogoutRedirectUri: window.runConfig.redirectUri + '/logout'
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false
  }
};

export const loginRequest = {
  scopes: window.runConfig.userScopes.split(','),
  loginHint: "",
};
