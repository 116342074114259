import React, { useState } from 'react'
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';

import { RootState } from "../../store/RootReducer";
import { User } from "../../models";
import { CancelPopup } from "./CancelPopup";
import { DeletePopup } from "./DeletePopup";

const useStyles = makeStyles(() => ({
  error__message: {
    color: "#af1f22",
    fontSize: "0.8rem",
    marginTop: 8,
  },
}));

interface Props {
  inError: boolean;
  isUserEdit: boolean;
  saving: boolean;
  editingUser: User | null;
  hasChangedForm: boolean;
}

export const ActionButtonsContainer = (props: Props): React.ReactElement => {
  const { inError, isUserEdit, saving, editingUser, hasChangedForm } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation("user");
  const userState = useSelector((state: RootState) => state.userState);
  const [openCancel, setOpenCancel] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const onCancel = () => {
    setOpenCancel(hasChangedForm);
    if (!hasChangedForm) {
      navigate("/");
    }
  };

  const onDelete = () => {
    setOpenDelete(true);
  };

  const closePopups = (goBack: boolean): void => {
    setOpenCancel(false);
    setOpenDelete(false);
    if (goBack) {
      navigate("/");
    }
  };

  return (
    <>
      <Grid item container direction="column" spacing={2}>
        <Grid item container direction="row" justify="space-between">
          <Grid item>
            {isUserEdit && userState.loggedInUser.id !== editingUser?.id ? (
              <Button
                color="primary"
                variant="text"
                disabled={saving}
                onClick={onDelete}
              >
                {t("delete")}
              </Button>
            ) : null}
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="text"
              className="m-r-16"
              disabled={saving}
              onClick={onCancel}
            >
              {t("cancel")}
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={saving}
              type="submit"
            >
              {saving ? (
                <CircularProgress color="inherit" size={28} />
              ) : (
                t("save")
              )}
            </Button>
          </Grid>
        </Grid>
        <Grid item container justify="flex-end">
          <Grid item>
            {inError ? (
              <span className={classes.error__message}>
                {t("save-failed")}
              </span>
            ) : (
              <span className={classes.error__message}>&nbsp;</span>
            )}
          </Grid>
        </Grid>
      </Grid>
      <CancelPopup open={openCancel && hasChangedForm} onClose={closePopups} />
      {editingUser ? (
        <DeletePopup
          open={openDelete}
          onClose={closePopups}
          email={editingUser.email}
          userId={editingUser.id}
        />
      ) : null}
    </>
  )
}
