import { combineReducers } from '@reduxjs/toolkit';

import userSlice from './UserSlice';

const rootReducer = combineReducers({
  userState: userSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
