import React, { useContext, useState } from 'react'
import Grid from '@material-ui/core/Grid';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Collapse from '@material-ui/core/Collapse';

import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import QueryBuilderRoundedIcon from '@material-ui/icons/QueryBuilderRounded';
import { makeStyles } from '@material-ui/core/styles';
import { Theme, Typography } from '@material-ui/core';

import { NotificationEvent } from '../../models';
import { EventContext } from '../../utils/SignalR/NotificationState';
import { useTranslation } from 'react-i18next';

type StateType = 'success' | 'failed' | 'ongoing' | 'wait' | 'successonad';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '18px 20px 20px 20px',
    position: 'relative',
  },
  message: {
    paddingTop: 5,
    fontSize: '0.9rem',
    lineHeight: '0.9rem'
  },
  time: {
    fontWeight: 300,
    fontSize: '0.7rem',
    lineHeight: '0.9rem'
  },
  close__icon: {
    position: 'absolute',
    top: 10,
    right: 10,
    transition: 'all 0.2s ease',
    color: theme.palette.grey[600],
    '&:hover': {
      cursor: 'pointer',
      transform: 'matrix(1.2, 0, 0, 1.2, 0, 0)',
      color: theme.palette.primary.main
    }
  }
}));

interface IconProps {
  state: StateType;
}

const Icon = (props: IconProps): React.ReactElement => {
  const { state } = props;
  return (
    <>
      {state === 'success' ? <QueryBuilderRoundedIcon style={{ color: orange[500] }} /> : null}
      { state === 'failed' ? <CancelRoundedIcon style={{ color: red[500] }} /> : null}
      { state === 'ongoing' ? <QueryBuilderRoundedIcon style={{ color: orange[500] }} /> : null}
      {state === 'wait' ? <QueryBuilderRoundedIcon style={{ color: orange[500] }} /> : null}
      {state === 'successonad' ? <CheckCircleRoundedIcon style={{ color: green[500] }} /> : null}
    </>
  )
}

interface Props {
  event: NotificationEvent;
}

export const Notification = (props: Props): React.ReactElement => {
  const { event } = props;
  const { t } = useTranslation('common');
  const classes = useStyles();
  const { deleteEvent } = useContext(EventContext);
  const [visible, setVisible] = useState(true);

  return (
    <Collapse in={visible} onExited={() => deleteEvent(event.eventId)}>
      <div className={classes.root}>
        <Grid container direction="column" spacing={0}>
          <Grid item container direction="row" spacing={1} alignItems="center" justify="flex-start">
            <Grid item>
              <Grid item container justify="center" alignItems="center">
                <Icon state={event.state} />
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                {t(`errors.${event.title}`, {firstName: event.firstName, lastName: event.lastName, email: event.email})}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item>
              <Typography className={classes.message}>
                {t(`errors.${event.message}`, { firstName: event.firstName, lastName: event.lastName, email: event.email })}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container justify="flex-end">
            <Typography className={classes.time}>
              {(new Date(event.lastUpdate)).toLocaleTimeString()}
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.close__icon}>
          <CloseRoundedIcon style={{ fontSize: 20 }} onClick={() => setVisible(false)} />
        </div>
      </div>
    </Collapse>
  )
}
