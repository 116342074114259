import React from 'react';
import { useMsal, IMsalContext } from '@azure/msal-react';
import { BrowserAuthError, InteractionRequiredAuthError } from '@azure/msal-browser';

interface IProps {
  setMsalRef: (msal: IMsalContext) => void;
}

const InnerMsalUtilsConfigurator: React.FC<IProps> = (props: IProps) => {
  props.setMsalRef(useMsal());
  return null;
};

let useMsalRef: IMsalContext;
const setUseMsalRef = (useMsalRefProp: IMsalContext) => {
  useMsalRef = useMsalRefProp;
};

export const MsalUtilsConfigurator = (): React.ReactElement => {
  return (
    <InnerMsalUtilsConfigurator setMsalRef={setUseMsalRef} />
  );
};

export default {
  async getAccessToken(scopes: string[]): Promise<string | null> {
    const accounts = useMsalRef.instance.getAllAccounts();
    const accessTokenRequest = {
      scopes,
      account: accounts[0]
    }

    return useMsalRef.instance.acquireTokenSilent(accessTokenRequest)
      .then(response => {
        if (response.accessToken) {
          return response.accessToken;
        }
        return null;
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError || error instanceof BrowserAuthError) {
          useMsalRef.instance
            .acquireTokenRedirect(accessTokenRequest)
        }
        return null;
      });
  },

  logout(): void {
    const accounts = useMsalRef.instance.getAllAccounts();
    if (accounts.length > 0) {
      useMsalRef.instance.logout({ account: accounts[0] });
    }
    useMsalRef.instance.logout();
  }
};
