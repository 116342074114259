import React from "react";
import StyledTableRow from './StyledTableRow';
import { TableCell, Grid, makeStyles, IconButton } from '@material-ui/core';
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";
import { Package } from '../../models'

interface Props {
    item: Package;
    saving: boolean;
    onDeleteClick: (itemId: string) => void;
}

const useStyles = makeStyles(() => ({
    package__lineOne: {
        fontWeight: "bold",
    },
    package__lineTwo: {
        paddingLeft: 20,
        fontSize: "0.8rem",
    },
    package__container: {
        maxWidth: "calc(100% - 48px)",
    },
}));

export const SelectedPackageItem: React.FC<Props> = (props: Props) => {

    const {
        item,
        saving,
        onDeleteClick 
    } = props;
    const classes = useStyles();

    return (
        <StyledTableRow>
            <TableCell>
                <Grid container direction="row" justify="space-between">
                    <Grid item className={classes.package__container}>
                        <div className={classes.package__lineOne}>
                            {item.displayName}
                        </div>
                        <div className={classes.package__lineTwo}>
                            {item.description}
                        </div>
                    </Grid>
                    <Grid item>
                        <IconButton
                            disabled={saving || item.starterPackage}
                            onClick={() => onDeleteClick(item.id)}
                        >
                            <DeleteRoundedIcon color={saving || item.starterPackage ? "disabled" : "primary"} />
                        </IconButton>
                    </Grid>
                </Grid>
            </TableCell>
        </StyledTableRow>
    )
};

export default SelectedPackageItem;
