import React, { ReactElement } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  item: {
    cursor: 'pointer',
    borderBottom: '6px solid rgba(0,0,0,0)',
    padding: '18px 8px 7px 8px !important',
    '&:hover': {
      color: '#af1f22'
    }
  },
  active: {
    borderBottom: '6px solid #af1f22',
    fontWeight: 600
  }
}));

const menuItems = [
  { title: "menu.users", path: "/users" },
  { title: "menu.packages", path: "/packages" }
];

export const Menu = (): ReactElement => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation('common');

  const navigateToPage = (path: string): void => {
    navigate(path);
  }

  return (
    <Grid item container xs={4} justify="center" spacing={2} direction="row" alignItems="flex-end">
      {
        menuItems.map((item) => (
          <Grid
            key={item.title}
            item
            className={classNames(classes.item, { [classes.active]: location.pathname.startsWith(item.path) })}
            onClick={() => navigateToPage(item.path)}
          >
            <span>
              {t(item.title)}
            </span>
          </Grid>
        ))
      }
    </Grid>
  )
}
