import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import { EnvironmentBanner } from '@atrias/react-atrias-components';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Users } from './pages/Users';
import { Packages } from './pages/Packages';
import { UserManagement } from './pages/UserManagement';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { loadLoggedInUserInfo } from './store/UserSlice';
import { MsalUtilsConfigurator } from './utils/MsalUtilsConfigurator';
import { UnAuthenticated, NotAllowed } from './pages/UnAuthenticated';
import LoggedOut from './pages/UnAuthenticated/LoggedOut';
import { EventContextProvider } from './utils/SignalR/EventContextProvider';
import { Toaster } from 'react-hot-toast';


const useStyles = makeStyles(() => ({
  header: {
    height: 50,
  },
  footer: {
    height: 50,
  },
  content: {
    flexGrow: 1,
  },
  container: {
    minHeight: '100vh',
  },
  toast: {
    backgroundColor: 'white',
    width: 450,
    minWidth: 450
  },
}));

const App: React.FC = () => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const dispatch = useDispatch();
  const { accounts } = useMsal();

  useEffect(() => {
    if (accounts.length > 0) {
      dispatch(loadLoggedInUserInfo(accounts[0].localAccountId ?? ""));
    }
  }, [dispatch, accounts]);

  return (
    <>
      <Helmet>
        <title>{t('app-title')} | Atrias</title>
        <meta name="description" content={t('meta-description')} />
        <meta name="robots" content="noindex"></meta>
      </Helmet>
      <AuthenticatedTemplate>
        <EventContextProvider>
          <BrowserRouter>
            <Grid container direction="column" className={classes.container}>
              {window.runConfig.environment !== 'PRD' ?
                (<Grid item>
                  <EnvironmentBanner
                    version={window.runConfig.version}
                    environment={window.runConfig.environment}
                    explanationText="This a test environment."
                  />
                </Grid>)
                : null}
              <Grid container item className={classes.footer}>
                <Header />

              </Grid>
              <Grid item container className={classes.content} direction="column">
                <Toaster
                  containerStyle={{
                    position: 'relative',
                    boxSizing: 'border-box',
                    inset: '16px 0 0 -16px'
                  }}
                  toastOptions={{
                    position: 'top-right',
                    duration: 5000,
                  }} />
                <Routes>
                  <Route path="/" element={<Navigate to="/users" />} />
                  <Route path="/users" element={<Users />} />
                  <Route path="/users/new" element={<UserManagement />} />
                  <Route path="/users/:userId" element={<UserManagement />} />
                  <Route path="/packages" element={<Packages />} />
                </Routes>
              </Grid>
              <Grid container item className={classNames(classes.footer, "m-t-32")}>
                <Footer />
              </Grid>
            </Grid>
          </BrowserRouter>
        </EventContextProvider>
      </AuthenticatedTemplate>
      <MsalUtilsConfigurator />
      <UnauthenticatedTemplate>
        <BrowserRouter>
          <Routes>
            <Route path="/blocked" element={<NotAllowed />} />
            <Route path="/logout" element={<LoggedOut />} />
            <Route path="*" element={<UnAuthenticated />} />
          </Routes>
        </BrowserRouter>
      </UnauthenticatedTemplate>
    </>
  );
}

export default App;
