import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 200,
  }
}));


export const NoResults = (): React.ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation('home');

  return (
    <Grid
        item
        container
        direction="column"
        justify="center"
        alignItems="center"
        xs={12}
        className={classes.root}
      >
        <Grid item>
          <p className="search-info-text">{t('no-results')}</p>
        </Grid>
      </Grid>
  )
}
