import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";

import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { RootState } from "../../store/RootReducer";
import { getPackageList } from "../../services/userService";
import { Package } from "../../models";
import { AccordionItem } from '.';

export const Packages = (): React.ReactElement => {
  const { t } = useTranslation('packages');
  const userState = useSelector((state: RootState) => state.userState);
  const [packages, setPackages] = useState<Package[]>();
  const [loadingPackageList, setLoadingPackageList] = useState(true);

  useEffect(() => {
    if (userState.loggedInUser.administrativeUnits[0]) {
      getPackageList(userState.loggedInUser.administrativeUnits[0])
        .then(
          (data) => {
            setPackages(data);
            setLoadingPackageList(false);
          }
        );
    }
  }, [userState.loggedInUser.administrativeUnits]);

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      className="p-t-16"
      alignItems="center"
    >
      <Grid
        item
        container
        direction="column"
        justify="flex-start"
        xs={12}
        md={10}
        xl={8}
        className="m-t-32"
      >
        <Grid item>
          <h1>{t('title')}</h1>
        </Grid>
        {
          loadingPackageList ?
            <Grid item container justify="center" alignItems="center" className="m-t-32">
              <Grid item className="m-r-8 m-t-4">
                <CircularProgress size={15} />
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">
                  {t('loading')}
                </Typography>
              </Grid>
            </Grid>
            : <div>
              {
                packages?.map((item: Package) => (
                  <AccordionItem item={item} key={item.id} />
                ))
              }
            </div>
        }
      </Grid>
    </Grid >
  )
}
