import { EndSessionRequest } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { Button } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

import { AuthenticationPage } from "../../components/AuthenticationPage";

export const NotAllowed = (): React.ReactElement => {
  const { t } = useTranslation("common");
  const { instance, accounts } = useMsal();

  const logout = () => {
    const logoutRequest: EndSessionRequest = {};

    if (accounts.length > 0 && accounts[0]?.homeAccountId) {
      const accountinfo = instance.getAccountByHomeId(accounts[0].homeAccountId);
      if (accountinfo) {
        logoutRequest.account = accountinfo;
      }
    }

    instance.logout(logoutRequest);
  }

  return <AuthenticationPage title={t("not-allowed.title")} content={t('not-allowed.content')} actions={<Button color="primary" variant="contained" fullWidth onClick={() => logout()}>{t('logout')}</Button>} />;
};
