import React, { ReactElement } from 'react';
import {
    Box,
    Typography,
    Modal
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Package, PackageAssignedUser, RowUser } from '../../models';
import { makeStyles } from '@material-ui/core/styles';
import { SelectUser } from './SelectUser';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    content: {
        margin: '8px 0',
        overflow: 'auto',
    },
    modalBox: {
        padding: '32px',
        width: '50%',
        height: '70%',
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#ffffff', // Replace with the actual color value
        borderRadius: '15px',
        boxShadow: '0px 7px 29px 0px #333333', // Replace with the actual shadow value
    },
    titleRow: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    buttonRow: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    buttonNo: {
        padding: '0 40px',
    },
}));

interface IModalComponent {
    usersWithPackage: PackageAssignedUser[];
    packageInfo: Package
    open: boolean;
    onClose: () => void;
    setUsersWithPackage: React.Dispatch<React.SetStateAction<PackageAssignedUser[] | null>>
    setUserNumber: React.Dispatch<React.SetStateAction<number>>
}

export const ModalComponent = (props: IModalComponent): ReactElement => {
    const { usersWithPackage, packageInfo, open, onClose, setUsersWithPackage, setUserNumber } = props

    const classes = useStyles();
    return (
        <>
            <Modal open={open} onClose={onClose}>
                <Box className={classes.modalBox}>
                    <div className={classes.titleRow}>
                        <Typography variant="h2" color="#b01c24">
                            {packageInfo.displayName}
                        </Typography>
                        <IconButton onClick={() => onClose()} style={{ backgroundColor: 'transparent' }}>
                            <CloseIcon
                                style={{
                                    color: 'black', // Set the default color
                                    transition: 'color 0.3s', // Add a smooth transition for the color change
                                }}
                                onMouseOver={(e) => (e.currentTarget.style.color = '#b01c24')} // Change color on hover
                                onMouseOut={(e) => (e.currentTarget.style.color = 'grey')} // Restore color on mouse out
                            />
                        </IconButton>
                    </div>

                    <br />
                    <SelectUser usersWithPackage={usersWithPackage} setUsersWithPackage={setUsersWithPackage} packageInfo={packageInfo} setUserNumber={setUserNumber} />
                </Box>
            </Modal>
        </>
    );
};

