import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { LastSignIn, LastSignInsPerUser, QueryParameters } from "../../models";
import Paper from '@material-ui/core/Paper';
import { getSignIns } from "../../services/userService";




const useStyles = makeStyles(() => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  table__container: {
    paddingTop: 32,
  }
}));

interface TableProps {
  rows: LastSignInsPerUser | undefined;
}

type OrderType = 'asc' | 'desc';
type OrderByType = 'applicationName' | 'lastSignIn'

export const LastSignInContainer = (lastSignInForUser: TableProps | undefined): React.ReactElement => {
  const { t } = useTranslation('user');
  const classes = useStyles();
  const [orderBy, setOrderBy] = useState<OrderByType>('applicationName')
  const [order, setOrder] = useState<OrderType>('asc')

  type header = {
    id: string;
    label: string;
    align: 'left' | 'right';
    sortable: boolean,
  };

  function compareStrings(a: string | undefined, b: string | undefined, order: OrderType): number {
    if (a === undefined && b === undefined) return 0;
    if (a === undefined) return order === 'asc' ? 1 : -1;
    if (b === undefined) return order === 'asc' ? -1 : 1;
    return order === 'asc' ? a.localeCompare(b) : b.localeCompare(a);
  }

  function compareDates(a: Date | undefined, b: Date | undefined, order: OrderType): number {
    const aTime = a ? new Date(a).getTime() : 0;
    const bTime = b ? new Date(b).getTime() : 0;
    return order === 'asc' ? aTime - bTime : bTime - aTime;
  }

  const sortTable = (cellId: OrderByType) => {
    setOrderBy(cellId)
    order === 'asc' ? setOrder('desc') : setOrder('asc')

    lastSignInForUser?.rows?.lastSignIn.sort((a, b) => {
      if (orderBy === 'applicationName') {
        return compareStrings(a.appDisplayName, b.appDisplayName, order);
      }
      return compareDates(a.lastLogin, b.lastLogin, order);
    }
    );
  }

  const headCells: header[] = [
    { id: 'applicationName', label: 'applicationName', align: 'left', sortable: true },
    { id: 'lastSignInDate', label: 'lastSignInDate', align: 'left', sortable: true }
  ];
  return (
    <Grid
      item
      container
      direction="column"
      xs={12}
      md={6}
      xl={4}
      className="p-8"
      spacing={2}
    >
      <TableContainer component={Paper} elevation={0}>
        <Table size="small">
          <TableHeaderContainer sortTable={sortTable} orderBy={orderBy} order={order} />
          <TableBodyContainer rows={lastSignInForUser?.rows?.lastSignIn} />
        </Table>
      </TableContainer>
    </Grid>

  )
}

interface TableHeaderContainerProps {
  sortTable: (cellId: OrderByType) => void
  orderBy: string
  order: 'asc' | 'desc'
}

const TableHeaderContainer = (props: TableHeaderContainerProps) => {
  const { t } = useTranslation('user');
  const classes = useStyles();
  const { orderBy, order, sortTable } = props

  type header = {
    id: string;
    label: string;
    align: 'left' | 'right';
    sortable: boolean,
  };

  const headCells: header[] = [
    { id: 'applicationName', label: 'applicationName', align: 'left', sortable: true },
    { id: 'lastSignIn', label: 'lastSignInDate', align: 'left', sortable: true },
  ];

  return (
    <TableHead>
      <TableRow>
        {
          headCells.map((cell) => (
            <TableCell
              key={cell.id}
              align={cell.align}
            >
              {cell.sortable
                ? (
                  <TableSortLabel
                    active={orderBy === cell.id}
                    direction={orderBy === cell.id ? order : 'asc'}
                    onClick={(event) => sortTable(cell.id as OrderByType)}
                  >
                    {t(cell.label)}
                  </TableSortLabel>
                )
                : t(cell.label)}
            </TableCell>
          ))
        }
      </TableRow>
    </TableHead>
  );
}

interface TableBodyProps {
  rows: LastSignIn[] | undefined;
}

export const TableBodyContainer = (lastSignIns: TableBodyProps | undefined): React.ReactElement => {
  const { t } = useTranslation('user');
  const classes = useStyles();

  return (
    <TableBody>
      {lastSignIns?.rows?.map((lastSignIn) => (
        <TableRow key={lastSignIn.appDisplayName}>
          <TableCell align="left">{lastSignIn.appDisplayName}</TableCell>
          <TableCell align="left">{lastSignIn && new Date(lastSignIn.lastLogin).toLocaleDateString("fr")}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
}
