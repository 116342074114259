import React, { ReactElement, useState, useEffect, useCallback } from "react";
import useDebounce from "../../utils/use-debounce";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import FormControl from "@material-ui/core/FormControl";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import UserTable from "./UserTable";
import { RootState } from "../../store/RootReducer";
import { getSignIns, getUserList } from "../../services/userService";
import { LastSignInsPerUser, ListContainer, QueryParameters, RowUser } from "../../models";
import { ReportPopup } from "./ReportPopup";

const useStyles = makeStyles(() => ({
  total__container: {
    color: "#979797",
  },
  fade__out: {
    opacity: 0.6,
  },
}));

export const Users = (): ReactElement => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation("home");
  const userState = useSelector((state: RootState) => state.userState);
  const [openReportPopup, setOpenReportPopup] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [rows, setRows] = useState<RowUser[]>([]);
  const [lastSignInsPerUser, setLastSignInsPerUser] = useState<LastSignInsPerUser[]>([]);
  const [isSearching, setIsSearching] = useState(true);
  const [totalUsers, setTotalUsers] = useState(0);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [doneFetching, setDoneFetching] = useState(false);
  const [orderByParam, setOrderByParam] = useState("displayName")
  const [searchQuery, setSearchQuery] = useState<QueryParameters>({
    order: "asc",
    orderBy: orderByParam,
    rowsPerPage: 25,
    page: 0,
  });

  const getMaximumDatePerUser = (userDisplayName: string, allSignIns: LastSignInsPerUser[], otherEmails: string[] | null) => {
    const userSignIns = allSignIns?.find(lastSignInPerUser => lastSignInPerUser.userPrincipalName?.toLowerCase() === userDisplayName?.toLowerCase() || otherEmails?.some(x => x.toLowerCase() == lastSignInPerUser.userPrincipalName?.toLowerCase()));
    let maxUserSignIn = userSignIns?.lastSignIn[0];
    userSignIns?.lastSignIn.forEach(function (userSignIn) {
      if (maxUserSignIn != undefined && userSignIn.lastLogin > maxUserSignIn?.lastLogin) {
        maxUserSignIn = userSignIn;
      }
    });
    return maxUserSignIn?.lastLogin;
  }

  const addSignInsToRaw = (listUser: RowUser[], allSignIns: LastSignInsPerUser[]) => {
    listUser.forEach(function (user) {
      user.lastSignIn = getMaximumDatePerUser(user.email, allSignIns, user.otherMails);
    });
    setDoneFetching(true);
  }

  const compareLastSignIn = (a: RowUser, b: RowUser) => {
    const aDate = a.lastSignIn ? new Date(a.lastSignIn) : null;
    const bDate = b.lastSignIn ? new Date(b.lastSignIn) : null;

    if (aDate && bDate) {
      return aDate.getTime() - bDate.getTime();
    } else if (aDate === null && bDate === null) {
      return 0;
    } else if (aDate === null) {
      return 1;
    } else {
      return -1;
    }
  };


  const orderDataItems = (dataItems: RowUser[]) => {
    const compareDisplayName = (a: RowUser, b: RowUser) => a.displayName.localeCompare(b.displayName);

    if (searchQuery.orderBy === 'displayName') {
      return searchQuery.order === 'asc'
        ? dataItems.sort(compareDisplayName)
        : dataItems.sort((a, b) => compareDisplayName(b, a));
    } else if (searchQuery.orderBy === 'lastSignIn') {
      return searchQuery.order === 'asc'
        ? dataItems.sort((a, b) => compareLastSignIn(a, b))
        : dataItems.sort((a, b) => compareLastSignIn(b, a));
    }
    return dataItems
  }

  const refreshTable = useCallback(
    async () => {
      let allSignIns: any;
      setIsSearching(true);
      getSignIns()
        .then((lastSignIns: LastSignInsPerUser[]) => {
          allSignIns = lastSignIns;
          setLastSignInsPerUser(lastSignIns);
        })
        // .catch(() => {console.log("Impossible to reach the signIns route")})
        .then(() => {
          getUserList(
            debouncedSearchTerm,
            searchQuery.order,
            searchQuery.orderBy,
            searchQuery.rowsPerPage,
            searchQuery.page,
            userState.loggedInUser.administrativeUnits[0]
          ).then((data: ListContainer<RowUser>) => {
            addSignInsToRaw(data.items, allSignIns);
            setRows(orderDataItems(data.items));
            setTotalUsers(data.totalItems);
            setIsSearching(false);
          })
        });

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
    debouncedSearchTerm,
    searchQuery,
    userState.loggedInUser.administrativeUnits]
  );

  useEffect(() => {
    if (userState.loggedInUser.administrativeUnits[0] !== undefined) {
      refreshTable();
    }
  }, [refreshTable, userState.loggedInUser.administrativeUnits]);

  const fetchRows = (
    order: "asc" | "desc",
    orderBy: string,
    rowsPerPage: number,
    page: number
  ): void => {
    setSearchQuery({
      order: order,
      orderBy: orderBy,
      page: page,
      rowsPerPage: rowsPerPage,
    });
  };


  const searchInputKeyPressed = (event: any) => {
    if (event.keyCode === 27) {
      setSearchTerm("");
    }
  };


  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      className="p-t-16"
      alignItems="center"
    >
      <Grid
        item
        container
        direction="column"
        justify="flex-start"
        xs={12}
        md={10}
        xl={8}
        className="m-t-32"
      >
        <Grid item container direction="row" justify="space-between">
          <Grid
            item
            container
            direction="row"
            xs={3}
            sm={6}
            alignItems="center"
          >
            <Grid item>
              <FormControl>
                <Input
                  placeholder={t("search-placeholder")}
                  onChange={(event) => setSearchTerm(event.target.value)}
                  value={searchTerm}
                  disabled={userState.loggedInUser.administrativeUnits[0] === undefined}
                  onKeyDown={searchInputKeyPressed}
                  endAdornment={
                    <InputAdornment position="end">
                      <SearchRoundedIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            {isSearching === true ? (
              <Grid item className="m-l-16">
                <CircularProgress size={15} />
              </Grid>
            ) : (
              <Grid
                item
                className={classNames("m-l-16", [classes.total__container])}
              >
                {totalUsers} {totalUsers === 1 ? t("result") : t("results")}
              </Grid>
            )}
          </Grid>
          <Grid item xs={9} sm={6} container justify="flex-end">
            <Button
              className="m-r-16"
              variant="contained"
              color="primary"
              disabled={userState.loggedInUser.administrativeUnits[0] === undefined}
              onClick={() => navigate("/users/new")}
            >
              <AddRoundedIcon /> {t("add-user")}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              disabled={userState.loggedInUser.administrativeUnits[0] === undefined}
              onClick={() => setOpenReportPopup(true)}
              className="m-r-16"
            >
              <CloudDownloadIcon />&nbsp;{t("export.export")}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              disabled={userState.loggedInUser.administrativeUnits[0] === undefined}
              onClick={refreshTable}
            >
              <RefreshRoundedIcon />&nbsp;{t("refresh")}
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          container
          className={classNames({ [classes.fade__out]: isSearching })}
        >

          <UserTable
            lastSignIns={lastSignInsPerUser}
            rows={rows}
            fetchRows={fetchRows}
            totalRows={totalUsers}
            queryParams={searchQuery}
          />
        </Grid>
      </Grid>
      <ReportPopup
        open={openReportPopup}
        onClose={() => setOpenReportPopup(false)}
      />
    </Grid>
  );

};
