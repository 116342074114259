import React from 'react'
import { useTranslation } from 'react-i18next';

import { AuthenticationPage } from "../../components/AuthenticationPage";

const LoggedOut = (): React.ReactElement => {
  const { t } = useTranslation('common');

  return (
    <AuthenticationPage
      title={t("logged-out.title")}
      content={t("logged-out.content")}
    />
  )
}

export default LoggedOut
