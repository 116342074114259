import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import Background from "./assets/background.jpg";

interface Background {
  background: string;
}

const useStyles = makeStyles(() => ({
  container: (props: Background) => ({
    backgroundImage: `url(${props.background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: '100%',
    height: '100%',
    position: 'absolute'
  }),
  content: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  card: {
    width: 350,
    borderRadius: 8
  },
  card__content: {
      height: '100%'
  }
}));

interface AuthenticationPageProps {
  title?: string;
  content?: string | React.ReactElement;
  actions?: React.ReactElement;
}

export const AuthenticationPage: React.FC<AuthenticationPageProps> = (
  props: AuthenticationPageProps
) => {
  const { title = null, content = null, actions = null } = props;
  const classes = useStyles({background: Background});

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Card className={classes.card} elevation={8}>
          <CardContent className={classes.card__content}>
            <Grid container direction="column" className={classes.card__content}>
              {title !== null ? (
                <Grid item>
                  <Typography variant="h2">{title}</Typography>
                </Grid>
              ) : null}
              {content !== null ? (
                <Grid item container alignItems="center" justify="center" direction="column" style={{height: 'calc(100% - 90px)'}}>
                  <Grid item className="m-v-32">
                    {typeof content === "string" ? (
                      <Typography variant="body1">{content}</Typography>
                    ) : (
                      content
                    )}
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </CardContent>
          {actions !== null ? <CardActions>{actions}</CardActions> : null}
        </Card>
      </div>
    </div>
  );
};
