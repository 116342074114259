import React, { ReactElement, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import { useMsal } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import AtriasLogo from '../../assets/images/atrias-logo.svg';
import { RootState } from '../../store/RootReducer';
import { User } from '../../models';
import { EndSessionRequest } from '@azure/msal-browser';
import { Menu } from '.';
import { NotificationsContainer } from '../Notifications';
import Divider from '@material-ui/core/Divider';

const getUserName = (user: User): string => {
  if (!user.firstName || !user.lastName) {
    return user.displayName;
  }

  return `${user.firstName} ${user.lastName}`
}

const useStyles = makeStyles(() => ({
  container: {
    height: 50,
    padding: 8,
    boxShadow: '0 1px 10px 1px rgb(0 0 0 / 15%)',
  },
  app__name: {
    fontWeight: 'bold',
    marginTop: 6,
    marginLeft: 8,
    fontSize: '1.2em',
  },
  text: {
    fontSize: "0.8rem",
    textTransform: 'uppercase',
    paddingTop: 3
  },
  subtext: {
    fontSize: "0.7rem",
  },
  icon__padding: {
    paddingTop: 3
  }
}));

export const Header = (): ReactElement => {
  const classes = useStyles();
  const userState = useSelector((state: RootState) => state.userState);
  const [user, setUser] = useState<User | null>(null);
  const { instance, accounts } = useMsal();
  const { t } = useTranslation('common');

  useEffect(() => {
    setUser(userState.loggedInUser);
  }, [userState]);

  const logout = () => {
    const logoutRequest: EndSessionRequest = {};

    if (accounts.length > 0 && accounts[0]?.homeAccountId) {
      const accountinfo = instance.getAccountByHomeId(accounts[0].homeAccountId);
      if (accountinfo) {
        logoutRequest.account = accountinfo;
      }
    }

    instance.logout(logoutRequest);
  }

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      className={classes.container}>
      <Grid item container xs={4}>
        <Link to="/">
          <img src={AtriasLogo} height="30" width="30" />
        </Link>
        <div className={classes.app__name}>
          <Link to="/">
            {t('app-title')}
          </Link>
        </div>
      </Grid>

      <Menu />

      {user != null ?
        <Grid item container xs={4} justify="flex-end" spacing={2}>
          <Grid item>
            <Grid item container direction="column" alignItems="flex-end" style={{ paddingRight: 4 }}>
              <Grid item className={classes.text}>{user.companyName}</Grid>
              <Grid item className={classes.subtext}>{getUserName(user)}</Grid>
            </Grid>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item>
            <NotificationsContainer />
          </Grid>
          <Grid item>
            <Grid item container justify="center" alignItems="center" className={classes.icon__padding}>
              <IconButton aria-label="logout" size="small" onClick={logout}>
                <ExitToAppRoundedIcon color="primary" fontSize="inherit" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        : null
      }
    </Grid>
  )
}
