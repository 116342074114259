import React, { createContext, useState } from 'react'
import { Notification } from '../../components/Notifications';
import { NotificationEvent } from '../../models';
import { toast } from 'react-hot-toast';
import { makeStyles, Paper } from '@material-ui/core';

export interface EventContextProps {
  connectionId: string;
  setConnectionId: (value: string) => void;
  isConnected: boolean,
  setIsConnected: (value: boolean) => void;
  events: NotificationEvent[],
  addNewEvent: (event: NotificationEvent) => void;
  setAllEventsRead: () => void;
  updateEvent: (event: NotificationEvent) => void;
  deleteEvent: (eventId: string) => void;
  addOrUpdateEvent: (eventId: NotificationEvent) => void;
  deleteAllEvents: () => void;
}

export const EventContext = createContext<EventContextProps>({
  connectionId: '',
  setConnectionId: () => ({}),
  isConnected: false,
  setIsConnected: () => ({}),
  events: [],
  addNewEvent: () => ({}),
  updateEvent: () => ({}),
  setAllEventsRead: () => ({}),
  deleteEvent: () => ({}),
  deleteAllEvents: () => ({}),
  addOrUpdateEvent: () => ({}),
});

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'white',
    width: 450,
  }
}));

const CustomToast = ({ event }: {event: NotificationEvent}) => {
  const classes = useStyles();

  return (
    <Paper elevation={3} className={classes.root}>
      <Notification  event={event} />
    </Paper>
  )
}

export const useNotifications = (): EventContextProps => {
  const [connectionId, setConnectionId] = useState<string>('');
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [events, setEvents] = useState<NotificationEvent[]>([]);

  const addNewEvent = (event: NotificationEvent): void => {
    setEvents(prev => [event, ...prev]);
  }

  const setAllEventsRead = (): void => {
    setEvents(prev => prev.map((x: NotificationEvent) => { return { ...x, read: true } }));
  }

  const updateEvent = (event: NotificationEvent): void => {
    setEvents(prev => {
      const items = [...prev];
      const index = items.map((x: NotificationEvent) => x.eventId).indexOf(event.eventId);
      if (index > -1) {
        const item = { ...items[index] };
        item.state = event.state;
        item.message = event.message;
        item.lastUpdate = event.lastUpdate;
        items[index] = item;

        // Only show a toast when the status is success or failed, this only happens for an updated event and not for newly created ones.
        if(event.state === 'failed' || event.state === 'successonad') {
          toast.custom(<CustomToast event={item} />);
        }
      }
      return items;
    })
  }

  const addOrUpdateEvent = (event: NotificationEvent): void => {
    setEvents(prev => {
      const items = [...prev];
      const index = items.map((x: NotificationEvent) => x.eventId).indexOf(event.eventId);
      if (index > -1) {
        const item = { ...items[index] };
        item.state = event.state;
        item.title = event.title;
        item.message = event.message;
        item.lastUpdate = event.lastUpdate;
        items[index] = item;

        
      } else {
        items.unshift(event);
      }

      // Only show a toast when the status is success or failed, this only happens for an updated event and not for newly created ones.
      if (event.state === 'failed' || event.state === 'successonad' || event.state === 'ongoing') {
        toast.custom(<CustomToast event={event} />);
      }
      return items;
    })
  }

  const deleteEvent = (eventId: string): void => {
    setEvents(prev => prev.filter(x => x.eventId !== eventId));
  }

  const deleteAllEvents = (): void => {
    setEvents([]);
  }

  return {
    connectionId, setConnectionId,
    isConnected, setIsConnected,
    events, addNewEvent, 
    updateEvent, deleteEvent,
    setAllEventsRead, deleteAllEvents,
    addOrUpdateEvent,
  }
}