import React from 'react'
import { Dialog, Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  dialog__paper: {
    padding: '16px 16px 32px 32px',
    borderRadius: 16,
    boxShadow: '0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 9px 46px 8px rgba(0, 0, 0, 0.12) 0 24px 38px 3px rgba(0, 0, 0, 0.14)',
  },
  action__container: {
    width: '100%',
    marginTop: 16,
  }
}));

interface Props {
  open: boolean;
  onClose: (goBack: boolean) => void;
}

export const CancelPopup = (props: Props): React.ReactElement => {
  const { open, onClose } = props;
  const classes = useStyles();
  const { t } = useTranslation('user');

  return (
    <Dialog
      disableScrollLock
      fullWidth
      maxWidth="sm"
      open={open}
      onBackdropClick={() => onClose(false)}
      classes={{
        paper: classes.dialog__paper,
      }}
    >

      <Grid container direction="column">
        <Grid item>
          <h1>{t('discard-changes')}</h1>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            {t('discard-changes-text')}
          </Typography>
        </Grid>
        <Grid
          item
          spacing={2}
          container
          justify="flex-end"
          direction="row"
          className={classes.action__container}
        >
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => onClose(true)}
            >
              {t('discard')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => onClose(false)}
            >
              {t('stay')}
            </Button>
          </Grid>
        </Grid>
      </Grid>

    </Dialog>
  )
}
