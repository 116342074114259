import { Grid, CircularProgress } from '@material-ui/core';
import React from 'react';

interface Props {
    loading: boolean;
    children: React.ReactNode;
    className?: string;
}

export const GenericLoader: React.FC<Props> = (props: Props) => {
    const { loading, children } = props;

    if (loading) {
        return (
            <Grid className={props.className} item container justify="center" alignContent="center" alignItems="center" >
                <CircularProgress color="primary"></CircularProgress>
            </Grid>
        )
    }

    return (
        <>
            {children}
        </>);
};

