import React, { useEffect, useState } from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import IconButton from '@material-ui/core/IconButton';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import TableContainer from '@material-ui/core/TableContainer';
import Grid from '@material-ui/core/Grid';

import { LastSignInsPerUser, QueryParameters, RowUser } from '../../models';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/RootReducer';
import { DeletePopup } from '../UserManagement';

const useStyles = makeStyles(() => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  table__container: {
    paddingTop: 32,
  }
}));

interface Props {
  lastSignIns: LastSignInsPerUser[] | undefined;
  rows: RowUser[];
  fetchRows: (order: 'asc' | 'desc', orderBy: string, rowsPerPage: number, page: number) => void;
  totalRows: number;
  queryParams: QueryParameters;
}

const UserTable = (props: Props): React.ReactElement => {
  const { rows, fetchRows, totalRows, queryParams } = props;
  const { t } = useTranslation('home');
  const classes = useStyles();
  const [openDelete, setOpenDelete] = useState(false);
  const [userToDelete, setUserToDelete] = useState({ email: '', id: '' });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [queryParams.page, queryParams.rowsPerPage]);

  const createSortHandler = (id: string): void => {
    const isAsc = queryParams.orderBy === id && queryParams.order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    fetchRows(newOrder, id, queryParams.rowsPerPage, queryParams.page);
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    fetchRows(queryParams.order, queryParams.orderBy, queryParams.rowsPerPage, newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    fetchRows(queryParams.order, queryParams.orderBy, newRowsPerPage, 0);
  };

  const onDeleteUser = (email: string, id: string) => {
    setUserToDelete({ email: email, id: id });
    setOpenDelete(true);
  }

  const onCloseDeletePopup = () => {
    setOpenDelete(false);
    setUserToDelete({ email: '', id: '' });
  }

  return (
    <Grid
      container
      direction="column"
      className={classes.table__container}
    >
      <TableContainer component={Paper} elevation={0}>
        <Table size="small">
          <TableHeaderContainer
            createSortHandler={createSortHandler}
            order={queryParams.order}
            orderBy={queryParams.orderBy}
          />
          <TableBodyContainer rows={rows} deleteUser={onDeleteUser} />
        </Table>
      </TableContainer>
      {
        rows.length !== 0
          ? (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 75, 100]}
              count={totalRows}
              rowsPerPage={queryParams.rowsPerPage}
              page={queryParams.page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              labelRowsPerPage={t('rows-per-page')}
              component="div"
            />
          )
          : null
      }
      <DeletePopup
        open={openDelete}
        onClose={onCloseDeletePopup}
        email={userToDelete.email}
        userId={userToDelete.id}
      />
    </Grid>
  )
}

interface TableHeaderContainerProps {
  createSortHandler: (id: string) => void;
  order: 'asc' | 'desc';
  orderBy: string;
}

const TableHeaderContainer = (props: TableHeaderContainerProps) => {
  const { createSortHandler, order, orderBy } = props;
  const { t } = useTranslation('home');
  const classes = useStyles();

  type header = {
    id: string;
    label: string;
    align: 'left' | 'right';
    sortable: boolean,
  };

  const headCells: header[] = [
    { id: 'displayName', label: 'displayName', align: 'left', sortable: true },
    { id: 'mail', label: 'email', align: 'left', sortable: false },
    { id: 'lastSignIn', label: 'lastSignInDate', align: 'left', sortable: true },
    { id: 'actions', label: '', align: 'right', sortable: false }
  ];

  return (
    <TableHead>
      <TableRow>
        {
          headCells.map((cell) => (
            <TableCell
              key={cell.id}
              align={cell.align}
              sortDirection={orderBy === cell.id ? order : false}
            >
              {cell.sortable
                ? (
                  <TableSortLabel
                    active={orderBy === cell.id}
                    direction={orderBy === cell.id ? order : 'asc'}
                    onClick={() => { createSortHandler(cell.id) }}
                  >
                    {t(cell.label)}

                    {orderBy === cell.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>
                )
                : t(cell.label)}
            </TableCell>
          ))
        }
      </TableRow>
    </TableHead>
  );
}

interface TableBodyContainerProps {
  rows: RowUser[];
  deleteUser: (email: string, id: string) => void;
}

const TableBodyContainer = (props: TableBodyContainerProps) => {
  const { rows, deleteUser } = props;
  const navigate = useNavigate();
  const userState = useSelector((state: RootState) => state.userState);

  return (
    <TableBody>
      {rows.map((row) => (
        <TableRow key={row.id}>
          <TableCell align="left">{row.displayName}</TableCell>
          <TableCell align="left">{row.email}</TableCell>
          <TableCell align="left">{row.lastSignIn ? new Date(row.lastSignIn).toLocaleDateString("fr") : "No login data"}</TableCell>
          <TableCell align="right" width={90}>
            <IconButton aria-label="delete" disableRipple onClick={() => navigate(`/users/${row.id}`)}>
              <EditRoundedIcon fontSize="small" />
            </IconButton>
            <IconButton aria-label="delete" disableRipple disabled={userState.loggedInUser.id === row.id} onClick={() => deleteUser(row.email, row.id)}>
              <DeleteRoundedIcon color={userState.loggedInUser.id === row.id ? 'disabled' : 'primary'} fontSize="small" />
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
}

export default UserTable
