import React, { ReactElement } from 'react'

import { EventContext, useNotifications } from './NotificationState';
import { SignalRConfigurator } from './SignalRConfigurator';

interface Props {
  children: ReactElement | ReactElement[];
}

export const EventContextProvider = (props: Props): ReactElement => {
  const { children } = props;
  const signalRContext = useNotifications();

  return (
    <EventContext.Provider value={signalRContext}>
      <SignalRConfigurator/>
      {children}
    </EventContext.Provider>
  );
}
