import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

let translationFilePath = '/locales/{{lng}}/{{ns}}.json';
if (process.env.NODE_ENV === 'development' && module.hot) {
  translationFilePath = '/locales/base/{{ns}}.json';
}

const namespaces = [
  'common',
  'home',
  'user',
  'footer'
];

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next);

export const initI18n = (): void => {
  i18n.init({
    detection: {
      order: [
        'querystring',
        'path',
        'navigator',
      ],
      checkWhitelist: true,
    },
    backend: {
      loadPath: translationFilePath,
    },
    ns: namespaces,
    whitelist: [
      'en',
      'base',
    ],
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
    },
  })
};

export const getLanguage = (): string =>
i18n.language || window.localStorage.i18nextLng;

export default i18n;