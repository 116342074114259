import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../assets/images/atrias-logo.svg";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    height: 50,
    fontFamily: "Open Sans, sans-serif",
    paddingLeft: 16,
    fontSize: "0.8rem",
  },
}));

export const Footer: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation('footer');

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="center"
      className={classes.root}
    >
      <Grid item>
        <img src={logo} width="16" className="m-t-4 m-r-8" />
      </Grid>
      <Grid item className="m-r-16">&#169; Atrias {new Date().getFullYear()}</Grid>
      <Grid item>
        <a href="https://atrias.topdesk.net/" target="_blank" rel="noreferrer">
          {t('topdesk')}
        </a>
      </Grid>
    </Grid>
  );
};
