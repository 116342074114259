import React from "react";
import { Theme, createStyles, withStyles } from "@material-ui/core/styles";

import { TableRow } from "@material-ui/core";

const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

export default StyledTableRow;
