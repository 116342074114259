import React from 'react'
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import { FieldElement, Ref } from 'react-hook-form/dist/types/fields';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';

interface Props {
  name: string;
  errors: any;
  register: (rules?: RegisterOptions) => (ref: (FieldElement & Ref)) => void;
  disabled: boolean;
  required?: boolean;
  onChange?: () => void;
}

const useStyles = makeStyles(() => ({
  "input-field": {
    width: '100%',
    height: 100,
  },
}));

export const RequiredInputField = (props: Props): React.ReactElement => {
  const {
    name,
    errors,
    register,
    disabled,
    required = false,
    onChange,
  } = props;
  const { t } = useTranslation('user');
  const classes = useStyles();

  return (
    <TextField
      label={t(`${name}.label`)}
      inputRef={register({
        required: {
          value: required,
          message: t(`${name}.required`)
        }
      })}
      className={classes["input-field"]}
      error={!!errors[name]}
      helperText={errors[name] ? errors[name].message : ""}
      defaultValue={''}
      name={name}
      disabled={disabled}
      onChange={onChange}
      variant="outlined"
      type={name === 'email' ? 'email' : 'text'}
    />
  )
}
