import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginRequest } from "../../services/msal-config";
import { useMsal } from "@azure/msal-react";
import { AuthError } from "@azure/msal-browser";
import { useTranslation } from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";
import { AuthenticationPage } from "../../components/AuthenticationPage";

export const UnAuthenticated = (): React.ReactElement => {
  const { t } = useTranslation("common");
  const { inProgress, instance, accounts } = useMsal();
  const [notAllowed, setNotAllowed] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (notAllowed) {
        navigate("/blocked");
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, [notAllowed, navigate]);

  useEffect(() => {
    loginRequest.loginHint = accounts[0]?.username ?? "";

    if (inProgress === "handleRedirect") {
      instance.ssoSilent(loginRequest).catch((error: AuthError) => {
        if (error && error.errorCode === "silent_sso_error") {
          setNotAllowed(true);
        }
      });
    }

    if (accounts.length === 0 && inProgress === "none") {
      instance.ssoSilent(loginRequest).catch((error: AuthError) => {
        if (error && error.errorCode === "silent_sso_error") {
          instance.loginRedirect(loginRequest);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts, instance]);

  return (
    <AuthenticationPage
      title={t("logging-in")}
      content={<CircularProgress size={60} />}
    />
  );
};
