import React, { useContext, useEffect, useState } from 'react'
import NotificationsNoneRoundedIcon from '@material-ui/icons/NotificationsNoneRounded';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Badge from '@material-ui/core/Badge';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import LinearProgress from '@material-ui/core/LinearProgress';
import classNames from 'classnames';

import { Notification } from '.';
import { EventContext } from '../../utils/SignalR/NotificationState';
import { NotificationEvent } from '../../models';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import clipboard from '../../assets/images/clipboard.jpg';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

interface StyleProps {
  color: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  root: {
    margin: '3px 0 3px 4px',
    position: 'relative',
    display: 'flex',
    width: 26,
  },
  container__root: {
    marginLeft: 4,
    width: 30,
    height: 34
  },
  container: {
    width: 450,
    position: 'absolute',
    right: -48,
    top: 39,
    zIndex: 9999, 
    maxHeight: 400,
    overflowY: 'auto'
  },
  badge__root: (props: StyleProps) => ({
    backgroundColor: props.color,
    right: -3,
    top: 2,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px'
  }),
  button__root: {
    backgroundColor: theme.palette.grey[400]
  }
}));

export const NotificationsContainer = (): React.ReactElement => {
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation('common');
  const { events, setAllEventsRead, deleteAllEvents } = useContext(EventContext);
  const classes = useStyles({ color: events.some(x => x.state === 'failed' && !x.read) ? red[500] : grey[500] });

  useEffect(() => {
    if (open) {
      setAllEventsRead();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events.length]);

  const toggleContainer = () => {
    setOpen(prev => !prev);
    setAllEventsRead();
    toast.remove();
  }

  const clearAll = () => {
    deleteAllEvents();
  }

  return (
    <Grid container direction="column" className={classes.container__root}>
      <Grid item>
        <div className={classes.root}>
          <Grid item>
            <IconButton
              aria-label="logout"
              size="small"
              onClick={() => toggleContainer()}
              classes={{
                root: classNames({[classes.button__root]: open} )
              }}
            >
              <Badge
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                badgeContent={events.filter((event: NotificationEvent) => (event.state === "successonad" || event.state === "failed") && !event.read).length}
                color="primary"
                classes={{
                  badge: classes.badge__root
                }}>
                <NotificationsNoneRoundedIcon color="primary" fontSize="inherit" />
              </Badge>
            </IconButton>
          </Grid>
          <Paper className={classes.container} square>
            <Collapse in={open}>
              <Grid container direction="column">
                <Grid container item justify="flex-end">
                  <Button
                    variant="text"
                    size="small"
                    className="m-r-8 m-t-8 m-b-8"
                    onClick={() => clearAll()}
                    startIcon={<DeleteOutlineRoundedIcon
                    />}>{t('clear-all')}</Button>
                </Grid>
                <Grid item>
                  <Divider />
                </Grid>
                {
                  events.length > 0 && (
                    events.map((event: NotificationEvent) => (
                      <Grid item key={event.eventId}>
                        <Notification event={event} />
                        <Divider />
                      </Grid>
                    ))
                  )
                }
                {
                  events.length == 0 && (
                    <Grid item container justify="center" alignItems="center" direction="column">
                      <Grid item>
                        <img src={clipboard} height="100" alt="clipboard icon" className="m-t-16" />
                      </Grid>
                      <Grid item className="m-b-16">
                        <Typography variant="subtitle1">
                          {t('no-events')}
                        </Typography>
                      </Grid>
                    </Grid>
                  )
                }
              </Grid>
            </Collapse>
          </Paper>
        </div>
      </Grid>
      <Grid item>
        {events.some((e) => e.state === "ongoing" || e.state === 'wait' || e.state === 'success') && <LinearProgress color="primary" variant="indeterminate" />}
      </Grid>
    </Grid>
  )
}
