import { CircularProgress, FormControl, Grid, Input, InputAdornment } from "@material-ui/core"
import classNames from "classnames"
import React, { ReactElement, useCallback, useEffect, useState } from "react"
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { RootState } from "../../store/RootReducer";
import { useTranslation } from "react-i18next";
import useDebounce from "../../utils/use-debounce";
import { getSignIns, getUserList, getUsersForPackage } from "../../services/userService";
import { LastSignInsPerUser, ListContainer, Package, PackageAssignedUser, QueryParameters, RowUser } from "../../models";
import { SuggestedUsers } from "./SuggestedUsers";


const useStyles = makeStyles(() => ({
    total__container: {
        color: "#979797",
    },
    fade__out: {
        opacity: 0.6,
    },
    selectUser: {
        flex: 1,
        overflow: "auto"
    }
}));

interface Props {
    usersWithPackage: PackageAssignedUser[]
    setUsersWithPackage: React.Dispatch<React.SetStateAction<PackageAssignedUser[] | null>>
    packageInfo: Package
    setUserNumber: React.Dispatch<React.SetStateAction<number>>
}

export const SelectUser = (props: Props): ReactElement => {
    const { usersWithPackage, setUsersWithPackage, packageInfo, setUserNumber } = props
    const classes = useStyles();
    const userState = useSelector((state: RootState) => state.userState);
    const { t } = useTranslation("home");
    const [searchTerm, setSearchTerm] = useState("");
    const [isSearching, setIsSearching] = useState(false);
    const [totalUsers, setTotalUsers] = useState(0);
    const debouncedSearchTerm = useDebounce(searchTerm, 10);
    const [orderByParam, setOrderByParam] = useState("displayName")
    const [usersSuggestions, setUsersSuggestions] = useState<RowUser[]>([])
    const [suggestionCount, setSuggestionCount] = useState<number>(0)
    const [searchQuery, setSearchQuery] = useState<QueryParameters>({
        order: "asc",
        orderBy: orderByParam,
        rowsPerPage: 25,
        page: 0,
    });

    const filterUnassignedUsers = (usersResult: RowUser[]) => {
        const hashId: { [key: string]: boolean } = {}
        usersWithPackage.forEach(user => hashId[user.id] = true)
        const usersWithoutCurrentPackage = usersResult.filter(user => !hashId[user.id])
        setUsersSuggestions(usersWithoutCurrentPackage)
        setSuggestionCount(usersWithoutCurrentPackage.length)
    }

    const searchInputKeyPressed = (event: any) => {
        if (event.keyCode === 27) {
            setSearchTerm("");
        }
    };

    const getMaximumDatePerUser = (userDisplayName: string, allSignIns: LastSignInsPerUser[]) => {
        const userSignIns = allSignIns?.find(lastSignInPerUser => lastSignInPerUser.userPrincipalName?.toLowerCase() === userDisplayName?.toLowerCase());
        let maxUserSignIn = userSignIns?.lastSignIn[0];
        userSignIns?.lastSignIn.forEach(function (userSignIn) {
            if (maxUserSignIn != undefined && userSignIn.lastLogin > maxUserSignIn?.lastLogin) {
                maxUserSignIn = userSignIn;
            }
        });
        return maxUserSignIn?.lastLogin;
    }

    const addSignInsToRaw = (listUser: RowUser[], allSignIns: LastSignInsPerUser[]) => {
        listUser.forEach(function (user) {
            user.lastSignIn = getMaximumDatePerUser(user.email, allSignIns);
        });
    }

    const compareLastSignIn = (a: RowUser, b: RowUser) => {
        const aDate = a.lastSignIn ? new Date(a.lastSignIn) : null;
        const bDate = b.lastSignIn ? new Date(b.lastSignIn) : null;

        if (aDate && bDate) {
            return aDate.getTime() - bDate.getTime();
        } else if (aDate === null && bDate === null) {
            return 0;
        } else if (aDate === null) {
            return 1;
        } else {
            return -1;
        }
    };

    const orderDataItems = (dataItems: RowUser[]) => {
        const compareDisplayName = (a: RowUser, b: RowUser) => a.displayName.localeCompare(b.displayName);

        if (searchQuery.orderBy === 'displayName') {
            return searchQuery.order === 'asc'
                ? dataItems.sort(compareDisplayName)
                : dataItems.sort((a, b) => compareDisplayName(b, a));
        } else if (searchQuery.orderBy === 'lastSignIn') {
            return searchQuery.order === 'asc'
                ? dataItems.sort((a, b) => compareLastSignIn(a, b))
                : dataItems.sort((a, b) => compareLastSignIn(b, a));
        }
        return dataItems
    }

    const refreshTable = useCallback(
        async () => {
            let allSignIns: any;
            setIsSearching(true);
            getSignIns()
                .then((lastSignIns: LastSignInsPerUser[]) => {
                    allSignIns = lastSignIns;
                })
                .catch(() => { console.log("Impossible to reach the signIns route") })
                .then(() => {
                    getUserList(
                        debouncedSearchTerm,
                        searchQuery.order,
                        searchQuery.orderBy,
                        searchQuery.rowsPerPage,
                        searchQuery.page,
                        userState.loggedInUser.administrativeUnits[0]
                    ).then((data: ListContainer<RowUser>) => {
                        addSignInsToRaw(data.items, allSignIns);
                        filterUnassignedUsers(orderDataItems(data.items));
                        setTotalUsers(data.totalItems);
                        setIsSearching(false);
                    })
                });

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [
        debouncedSearchTerm,
        searchQuery,
        userState.loggedInUser.administrativeUnits]
    );

    useEffect(() => {
        if (userState.loggedInUser.administrativeUnits[0] !== undefined) {
            refreshTable();
        }
    }, [refreshTable, userState.loggedInUser.administrativeUnits]);

    return (
        <Grid className={classes.selectUser} >
            <Grid item>
                <FormControl>
                    <Input
                        placeholder={t("Search users to add")}
                        onChange={(event) => setSearchTerm(event.target.value)}
                        value={searchTerm}
                        disabled={userState.loggedInUser.administrativeUnits[0] === undefined}
                        onKeyDown={searchInputKeyPressed}
                        endAdornment={
                            <InputAdornment position="end">
                                <SearchRoundedIcon />
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </Grid>
            {isSearching === true ? (
                <Grid item className="m-l-16">
                    <CircularProgress size={15} />
                </Grid>
            ) : (
                <Grid
                    item
                    className={classNames("m-l-16", [classes.total__container])}
                >
                    <SuggestedUsers suggestions={usersSuggestions} setUsersSuggestions={setUsersSuggestions} packageInfo={packageInfo} usersWithPackage={usersWithPackage} setUsersWithPackage={setUsersWithPackage} setUserNumber={setUserNumber} setSuggestionCount={setSuggestionCount} />
                    {suggestionCount} {suggestionCount === 1 ? t("result") : t("results")}
                </Grid>
            )}
        </Grid>
    )
}