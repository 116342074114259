import { axiosInstance } from "./axiosInstance";
import { saveAs } from 'file-saver';
import { AxiosResponse } from "axios";

export const getAllUsersReport = (administrativeUnitName: string): Promise<void> => {
  return axiosInstance
    .get<Blob>(`/${administrativeUnitName}/users/all`, { responseType: 'blob', timeout: 120000 })
    .then((response) => {
      saveFile(response);
    });
};

const saveFile = (response: AxiosResponse<Blob>): void => {
  if (response.status !== 200) {
    throw new Error(response.statusText);
  }

  const filename = response.headers['content-disposition']
    .split(';')
    .find((n: string) => n.includes('filename='))
    .replace('filename=', '')
    .replace(/"/g, '')
    .trim();

  saveAs(response.data, filename);
};