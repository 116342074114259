import React, { useEffect, useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { CountLoader } from "../../components/CountLoader";
import { Package, PackageAssignedUser } from "../../models";
import { getUsersForPackage } from "../../services/userService";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ModalComponent } from './ModalComponent'
import { Button } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  subtext: {
    fontSize: "0.75rem",
    fontWeight: 400
  },
  title: {
    fontSize: "0.93rem"
  },
  fixed__width: {
    minWidth: 10
  },
  flex__left: {
    flex: 'auto'
  },
  flex__right: {
    flex: '0 0 30px'
  },
  flex__container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  name__column: {
    width: "33%"
  },
  floatRight: {
    float: 'right'
  }
}));

interface Props {
  item: Package
}

export const AccordionItem = (props: Props): React.ReactElement => {
  const { item } = props;
  const { t } = useTranslation('packages');
  const classes = useStyles();
  const [usersWithPackage, setUsersWithPackage] = useState<PackageAssignedUser[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userNumber, setUserNumber] = useState<number>(item.userCount)
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    console.log();
  }, [usersWithPackage, setUsersWithPackage, userNumber])

  const fetchUsers = async (packageId: string) => {
    setIsLoading(true);
    let users = await getUsersForPackage(packageId);
    users = users.sort((a, b) => a.displayName > b.displayName ? 1 : -1);
    setUsersWithPackage(users);
    setUserNumber(users.length)
    setIsLoading(false);
  }

  const StyledTableRow = withStyles((theme: Theme) =>
    createStyles({
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
      },
    }),
  )(TableRow);

  const onClose = () => setOpen(false)
  const onOpen = () => setOpen(true)

  return (
    <Accordion key={item.id} elevation={3}>
      <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />} onClick={() => fetchUsers(item.id)}>
        <div className={classes.flex__container}>
          <div className={classes.flex__left}>
            <Grid item container direction="column">
              <Grid item>
                <Typography variant="h6">{item.displayName}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2" className={classes.subtext}>
                  {item.description.substring(0, 200)} {item.description.length > 200 ? '...' : null}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <div className={classes.flex__right}>
            <CountLoader count={userNumber} />
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column">
          {
            isLoading
              ? <Grid item container justify="center" alignItems="center" className="m-t-32">
                <Grid item className="m-r-8 m-t-4">
                  <CircularProgress size={15} />
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">
                    {t('loading-users')}
                  </Typography>
                </Grid>
              </Grid>
              : null
          }
          {
            !isLoading && usersWithPackage && usersWithPackage.length === 0
              ?
              <div>
                <Grid item container justify="center" alignItems="center" className="m-t-32">
                  <Grid item>
                    <Typography variant="subtitle1">
                      {t('no-users')}
                    </Typography>
                  </Grid>
                </Grid>
                {/* <AddUserToPackage item={item} fetchUsers={fetchUsers} classes={classes}></AddUserToPackage> */}
                <br />
                <Button
                  className="m-r-16"
                  variant="contained"
                  color="primary"
                  onClick={() => onOpen()}
                >
                  Grant access rights to multiple users
                </Button>
                <ModalComponent usersWithPackage={usersWithPackage} packageInfo={item} open={open} onClose={onClose} setUsersWithPackage={setUsersWithPackage} setUserNumber={setUserNumber} />
              </div>

              : null
          }
          {
            !isLoading && usersWithPackage && usersWithPackage.length > 0
              ?
              <div>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Email</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {usersWithPackage?.map((user: PackageAssignedUser) => (
                      <StyledTableRow key={user.id} hover>
                        <TableCell component="th" scope="row" className={classes.name__column}>{user.displayName}</TableCell>
                        <TableCell>{user.email}</TableCell>
                      </StyledTableRow>
                    ))}

                  </TableBody>
                </Table>
                {/* <AddUserToPackage item={item} fetchUsers={fetchUsers} classes={classes}></AddUserToPackage> */}
                <br />
                <Button
                  className="m-r-16"
                  variant="contained"
                  color="primary"
                  onClick={() => onOpen()}
                >
                  Grant access rights to multiple users
                </Button>
                <ModalComponent usersWithPackage={usersWithPackage} packageInfo={item} open={open} onClose={onClose} setUsersWithPackage={setUsersWithPackage} setUserNumber={setUserNumber} />
              </div>
              : null
          }
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}
