import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from '.';
import { User, UserContainer } from '../models';
import { getUserInfo } from '../services/userService';

const initialUser: User = {
  companyName: '',
  firstName: '',
  displayName: '',
  id: '',
  lastName: '',
  email: '',
  administrativeUnits: [],
  accessPackages: [],
  otherMails: []
};

const initialState: UserContainer = {
  loggedInUser: initialUser,
  loggedInUserLoaded: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    receiveUserInfo(state, action: PayloadAction<User>) {
      return {
        ...state,
        loggedInUser: action.payload,
        loggedInUserLoaded: true,
      };
    },
    alreadyLoaded(state) {
      return state;
    }
  },
});

export const loadLoggedInUserInfo = (userId: string): AppThunk => async (
  dispatch,
  getState
) => {
  if (!getState().userState.loggedInUserLoaded) {
    getUserInfo(userId).
      then((user) => {
        dispatch(userSlice.actions.receiveUserInfo(user));
      })
  } else {
    dispatch(userSlice.actions.alreadyLoaded());
  }
};

export default userSlice.reducer;
