import React, { useState } from 'react'
import { Dialog, Grid, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from 'react-redux';

import { getAllUsersReport } from '../../services/reportService';
import { RootState } from '../../store/RootReducer';

const useStyles = makeStyles(() => ({
  dialog__paper: {
    padding: '16px 16px 32px 32px',
    borderRadius: 16,
    boxShadow: '0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 9px 46px 8px rgba(0, 0, 0, 0.12) 0 24px 38px 3px rgba(0, 0, 0, 0.14)',
  },
  action__container: {
    width: '100%',
    marginTop: 16,
  },
  button__export: {
    width: 94
  }
}));

interface Props {
  open: boolean;
  onClose: () => void;
}

export const ReportPopup = (props: Props): React.ReactElement => {
  const { open, onClose } = props;
  const classes = useStyles();
  const { t } = useTranslation('home');
  const userState = useSelector((state: RootState) => state.userState);
  const [isDownloading, setIsDownloading] = useState(false);

  const startExport = async () => {
    setIsDownloading(true);
    await getAllUsersReport(userState?.loggedInUser.administrativeUnits[0]);
    setIsDownloading(false);
    onClose();
  }

  return (
    <Dialog
      disableScrollLock
      fullWidth
      maxWidth="sm"
      open={open}
      onBackdropClick={() => !isDownloading && onClose()}
      classes={{
        paper: classes.dialog__paper,
      }}
    >
      <Grid container direction="column">
        <Grid item>
          <h1>{t('export.title')}</h1>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            {t('export.content')}
          </Typography>
        </Grid>
        <Grid
          item
          spacing={2}
          container
          justify="flex-end"
          direction="row"
          className={classes.action__container}
        >
          <Grid item>
            <Button
              color="primary"
              variant="text"
              disabled={isDownloading}
              onClick={() => onClose()}
            >
              {t('export.cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              disabled={isDownloading}
              onClick={startExport}
              className={classes.button__export}
            >
              {
                isDownloading
                  ? <CircularProgress color="inherit" size={28} />
                  : t('export.export')
              }
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  )
}
